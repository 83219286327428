:root {
  --white: rgba(255, 255, 255, 1);
  --black: rgba(0, 0, 0, 1);
  --primary: rgba(15, 19, 40, 1);
  --primary50: rgba(15, 19, 40, 0.5);
  --primary25: rgba(15, 19, 40, 0.25);
  --primarySecond: rgba(196, 113, 82, 1);
  --primaryThird: rgba(38, 35, 66, 1);
  --primaryForth: rgba(196, 196, 196, 1);
  --primaryFifth: rgba(136, 133, 151, 1);

}

@font-face {
  font-family: 'optimusprincepsregular';
  src: url('./assets/fonts/optimusprinceps-webfont.woff2') format('woff2'),
  url('./assets/fonts/optimusprinceps-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'optimusprincepssemiboldRg';
  src: url('./assets/fonts/optimusprincepssemibold-webfont.woff2') format('woff2'),
  url('./assets/fonts/optimusprincepssemibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  font-size: 1.11111vw;
  box-sizing: border-box;
  scroll-behavior: smooth;
  
  @media (max-width: 834px) {
    font-size: max(13px, 1.92vw);
  }
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: var(--primary);
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.page-container {
  width: 74.444%;
  margin: 0 auto;

  @media (max-width: 834px) {
    width: 90%;
  }
}

.page-container-mini {
  width: 90%;
  margin: 0 auto;
}

.img-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
